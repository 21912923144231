<template>
    <div>
      <div v-if="rldata.screen_flags.show_acc_info" class="p-5">
        



            <div class="row">
                <div class="card-body col-12 col-md-6">
                    <h3>Account information</h3>
                    <div class="row mt-3">
                        <form v-on:submit.prevent="update__full_name" class="forms-sample col-12 col-md-6 text-center">

                            <div class="input-group mb-3">
                                <!-- <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">Full Name</span>
                                </div> -->
                                <input  type="text" id="user_surname" class="form-control" aria-label="Фамилия" aria-describedby="basic-addon1" v-model="rldata.new__user_data.full_name">
                            </div>

                            <div class="input-group mb-3">
                                <!-- <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">Email</span>
                                </div> -->
                                <input type="email" id="email" class="form-control" aria-label="Email" aria-describedby="basic-addon1" readonly v-model="rldata.user.email">
                            </div>

                            <button type="submit" class="save_btn w-100">Save</button>
                        </form>

                        <!-- <div class="offset-0 offset-md-2 mt-3 mt-md-0 d-flex flex-column col-12 col-md-4">
                            <div style="position: relative;" class="border rounded border-secondary d-flex justify-content-between align-items-center" v-bind:class="{ 'disabled_social p-2': rldata.user.gg_id }">
                                <div class="ulogin-button-google social_img_cards social_google"></div>
                                <p v-if="!(rldata.user.gg_id)" class="m-0 social_connect w-100 text-end p-2" v-on:click="add_social" data-uloginbutton="Google">Connect</p>
                                <p v-else class="m-0"><i class="mdi mdi-24px mdi-check-bold mr-2 text-primary"></i></p>
                            </div>
                        </div>   -->
                    </div>

                </div>
                <div class="d-none d-md-flex col-3"></div>
            </div>

            <!-- <div class="row mt-5">
                <div class="card-body col-12 col-sm-4 col-md-5" style="background-color: lightgrey; padding: 35px;">
                    <h4>Change name</h4>
                    <form v-on:submit.prevent="update__full_name" class="forms-sample text-center mt-3">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">Full Name</span>
                            </div>
                            <input type="text" id="new__adult_name" class="form-control" aria-describedby="basic-addon1" v-model="rldata.new__user_data.full_name">
                        </div>

                        <button type="submit" class="btn btn-dark w-100">Save</button>
                    </form>
                </div>

                <div class="offset-0 offset-md-1 col-12 col-md-6">
                    <div class="w-50" style="background-color: lightgrey; padding: 35px;">
                        <h6>Privacy policy. terms of use</h6>
                    </div>
                </div>
            </div> -->
            

            <div class="row mt-5">
                <div class="card-body col-12 col-md-6">
                    <h4>Change password</h4>
                    <div class="row mt-3">
                        <form v-on:submit.prevent="update__password" class="forms-sample text-center col-12 col-md-6">

                            <div class="input-group mb-3">
                                <!-- <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">Old password</span>
                                </div> -->
                                <input placeholder="Old password" type="password" id="old_password" class="form-control" aria-describedby="basic-addon1" v-model="rldata.new__user_data.old_password">
                            </div>

                            <div class="input-group mb-3">
                                <!-- <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">New password</span>
                                </div> -->
                                <input placeholder="New password" type="password" id="new_password" class="form-control" aria-describedby="basic-addon1" v-model="rldata.new__user_data.new_password">
                            </div>

                            <div class="input-group mb-3">
                                <!-- <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">Confirm new password</span>
                                </div> -->
                                <input placeholder="Repeat password" type="password" id="new_password_confirmation" class="form-control" aria-describedby="basic-addon1" v-model="rldata.new__user_data.new_password_confirmation">
                            </div>

                            <button type="submit" class="save_btn w-100">Save</button>
                        </form>
                    </div>
                </div>
                <div class="d-none d-md-flex col-3"></div>
            </div>










      </div>
    </div>
</template>

<script>
// import axios from "axios"
import router from "@/router"
var rldata = {
    user: null,
    screen_flags: {
        show_acc_info: false
    },

    new__user_data: {
        full_name: '',
        
        old_password: '',
        new_password: '',
        new_password_confirmation: '',
    },
}
export default {
  name: "Profile",
  data () {
    return {
      rldata
    }
  },
  methods: {
    
    update__password: function(){
        if( (this.rldata.new__user_data.old_password.length > 4 && this.rldata.new__user_data.old_password.length < 10) &&
            (this.rldata.new__user_data.new_password.length > 4 && this.rldata.new__user_data.new_password.length < 10) &&
            (this.rldata.new__user_data.new_password_confirmation.length > 4 && this.rldata.new__user_data.new_password_confirmation.length < 10) &&
            this.rldata.new__user_data.new_password == this.rldata.new__user_data.new_password_confirmation &&
            this.rldata.new__user_data.new_password != this.rldata.new__user_data.old_password){
            let data = {
                adult_id: this.rldata.user._id,
                password: this.rldata.new__user_data.old_password,
                new_password: this.rldata.new__user_data.new_password
            };
            this.$root.update__adult_password(data);
        } else {
            alert('Nothing to update, or new password doesnt match');
        }
    },

    update__full_name: function(){
        if(this.rldata.new__user_data.full_name.length > 0 && this.rldata.new__user_data.full_name != this.rldata.user.full_name){
            let data = {
                adult_id: this.rldata.user._id,
                full_name: this.rldata.new__user_data.full_name
            };
            this.$root.update__adult_name(data);
        } else {
            alert('Nothing to update');
        }
    },

    add_social: function(event) {
        console.log('here');
        // localStorage.setItem('social_operation', 'connect');
        // localStorage.setItem('user_id', this.$root.user._id);
        let button = event.target.getAttribute('data-uloginbutton');
        console.log(button);
        // switch(button){
        //   case "VK":
        //     console.log('vk');
        //     window.location.href = `${this.$root.rdata.vk_to_profile}`;
        //     break;

        //   case "Facebook":
        //     console.log('Facebook');
        //     window.location.href = `${this.$root.rdata.facebook_to_profile}`;
        //     break;

        //   case "Google":
        //     console.log('Google');
        //     window.location.href = `${this.$root.rdata.google_to_profile}`;
        //     break;

        //   default:
        //   console.log('none');
        //   window.location.reload();
        // }
    },
  },
  mounted () {
    try{
        this.$root.getUserData(this.rldata);

        // $(document).ready(function() {
        //     $("#show_hide_password a").on('click', function(event) {
        //         event.preventDefault();
        //         if($('#show_hide_password input').attr("type") == "text"){
        //             $('#show_hide_password input').attr('type', 'password');
        //             $('#show_hide_password i').addClass( "fa-eye-slash" );
        //             $('#show_hide_password i').removeClass( "fa-eye" );
        //         }else if($('#show_hide_password input').attr("type") == "password"){
        //             $('#show_hide_password input').attr('type', 'text');
        //             $('#show_hide_password i').removeClass( "fa-eye-slash" );
        //             $('#show_hide_password i').addClass( "fa-eye" );
        //         }
        //     });
        // });
      } catch(err){
        this.$root.logout();
      }
  },
   watch: {
     "rldata.user": function(handler){
       if(handler != null) {


        this.rldata.new__user_data.full_name = this.rldata.user.full_name;
        // switch(rldata.user.gr){
        //   case "GR_PARENT":
        //     if(rldata.user.childs.length == 0){
        //       router.push("/parents/child_connect");
        //     }
        //     break;

        //   default:
        //   console.log('none');
        // }

        rldata.screen_flags.show_acc_info = true;

       }
     }
   }
}
</script>

<style scoped>
  .social_img_cards {
    cursor: pointer;
    padding: 0px; 
    outline: none; 
    border: none; 
    border-radius: 0px;
    /* position: relative;  */
    display: inherit; 
    width: 32px; 
    height: 32px; 
    /* left: 0px; 
    top: 0px;  */
    position: absolute;
    top: 50%;
    left: 2%;
    transform: translate(2%, -50%);
    box-sizing: content-box;
  }

  .social_google{
    background: url('/resource/img/social_logos.png') 0px -206px / 32px no-repeat;
  }
  .social_connect{
    z-index: 999;
    font-weight: 600;
    cursor: pointer;
  }
  .disabled_social {
    background-color: aliceblue;
  }
</style>